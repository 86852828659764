<template>
  <div class="landing-page">
    <div class="container">
      <div class="card-12">
        <div class="image">
          <img :src="backgroundImg" />
        </div>
      </div>
      <div class="line"></div>
      <div class="card12 flex justify-center align-center">
        <div class="card-container">
          <div class="text-h3 white--text text-center">
            ¿Tu Iglesia tiene presencia en la Web / Internet?
          </div>
          <div class="flex justify-center mt-10">
            <button
              @click="$router.push('/pricingplan')"
              class="mt--10 card-button"
            >
              Si tu respuesta es No, Comienza hoy
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="directorio12 mt-10">
      <div class="white--text Title">
        ¿ Ya eres parte de nuestro directorio ?
      </div>
      <div class="flex-row justify-content-center align-items-center gap-3">
        <!-- <router-link class="accesar" to="/">Accesar</router-link>
                <div class="accesar pl-10 pr-10">|</div> -->
        <router-link :to="{ name: 'directorio' }" class="accesar"
          >Ver Directorio</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import bg from "../assets/images/ABC_Churchs_Negro.png";
import fav from "../../public/favicon.png"
export default {
  data() {
    return {
      backgroundImg: bg,
      link: fav
    };
  },
  mounted() {
    document.title = "Crear";
    let $favicon = document.querySelector('link[rel="icon"]');
 
    // If a <link rel="icon"> element already exists,
    // change its href to the given link.
    if ($favicon !== null) {
      $favicon.href = this.link;
      // Otherwise, create a new element and append it to <head>.
    } else {
      $favicon = document.createElement("link");
      $favicon.rel = "icon";
      $favicon.href = this.link;
      document.head.appendChild($favicon);
    }
  },
};
</script>

<style lang="scss" scoped>
.landing-page {
  width: 100%;
  height: 100vh;
  background-color: black;
  // border: gray 2px solid;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .container {
    // background-color: red;
    height: 400px;
    display: flex;

    gap: 10px;
    .line {
      border-color: #1fed46;
      border-width: 10px;
      border-radius: 10px;
      border-style: solid;
      border-right: none;
      border-top: none;
      border-bottom: none;
      @media (max-width: 767.98px) {
        border-style: solid;
        border-right: none;
        border-left: none;
        border-width: 10px;
        border-radius: 10px;
        border-top: solid;
        border-bottom: solid;
        color: #1fed46;
      }
    }

    .image {
      height: 100%;
      background-color: #000000;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .BG-image-left {
      // background-image: url("/assets/images/church/ABC_Churchs_Negro.png");
      background-size: contain;

      height: 100%;
      background-repeat: no-repeat;
    }

    @media (max-width: 767.98px) {
      flex-direction: column;
    }

    .gradient-black {
      background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0.3)
      );
    }

    .card-12 {
      width: 100%;
      position: relative;
      // background-color: red;
      border-radius: 0;
      border: none;
      .card-container {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .Title {
        font-size: 36px;
        text-align: center;
        @media (max-width: 767.98px) {
          font-size: 20px;
        }
      }

      .card-button {
        background-color: #1fed46;
        border-radius: 20px !important;
        font-size: 16px;
        width: 350px;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          border-color: white;

          color: black;
        }
      }
    }
  }
  .directorio12 {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 60px;
    justify-content: flex-start;

    .gap-3 {
      margin-top: 3px;
      gap: 10px;
    }
    .line {
      border-color: #1fed46;
      top: 0;
      border-width: 2px;
      border-radius: 10px;
      border-style: solid;
      border-right: none;
      border-top: none;
      border-bottom: none;
    }
    .Title {
      font-size: 14px;
      text-align: center;
    }
    .accesar {
      margin-top: -10px;
      background: none;
      color: #1fed46;
      display: flex;
      justify-content: center;
      font-size: 14px;
    }
    .SubTitle {
      font-size: 34px;
      text-align: center;
    }
  }
}

.card-button {
  background-color: black;
  color: #1fed46;

  border-radius: 20px !important;
  font-size: 18px;
  width: 350px;

  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: black;
    transform: scale(1.1);
    color: #1fed46;

    color: black;
    background-color: #1fed46;
  }
}
</style>
